section {

  .container,
  .container-fluid{
    padding-right: 0;
    padding-left: 0;

    .row {
      padding-right: 0;
      padding-left: 0;
      --bs-gutter-x: 0;
      --bs-gutter-y: 0;

      .col {
        border: 1px solid red;
        border: 0px solid transparent !important;

        @include media-breakpoint-up(sm) {
          border: 1px solid yellow;
        }
        @include media-breakpoint-up(md) {
          border: 2px solid green;
        }
        @include media-breakpoint-up(lg) {
          border: 1px solid blue;
        }
        @include media-breakpoint-up(xl) {
          border: 1px solid red;
        }
        @include media-breakpoint-up(xxl) {
          border: 1px solid orange;
        }

        .section_header {
          width: 100%;
          min-height: 100px;
          height: auto;

          .container,
          .container-fluid{
            height: 100%;
            //padding-left: 20px;
            //padding-right: 20px;

            .row {
              height: 100%;

              .col {
                padding: 20px 0;
                height: 100%;
                min-height: 100px;
                display: flex;
                align-items: center;
                //justify-content: center;
                //align-items: flex-end;
                //padding-bottom: 10px;
                //padding: 20px;
              }
            }
          }
        }

        .section_content {
          padding-top: 50px;

          .container,
          .container-fluid{

            .row {
              padding-left: 0;
              padding-right: 0;

              .col {
                width: 100%;
                overflow-x: hidden;

                /* stylelint-disable no-descending-specificity */
                .table_wraper{
                  margin: 20px 0px 50px 0px;
                  width: 100%;
                  height: auto;
                  overflow: hidden;
                  //border: 1px solid red;

                  .table {
                    width: 120% !important;
                    height: 100%;

                    a {
                      text-decoration: underline;

                      &:hover {
                        text-decoration: none !important;
                      }
                    }

                    @include media-breakpoint-up(sm) {
                      width: 100% !important;
                    }
                    @include media-breakpoint-up(md) {
                      width: 100% !important;
                    }
                    @include media-breakpoint-up(lg) {
                      width: 100% !important;
                    }
                    @include media-breakpoint-up(xl) {
                      width: 100% !important;
                    }
                  }

                  table.dataTable th,
                  table.dataTable td {
                    font-size: .9em;
                    vertical-align: middle;
                    text-align: center;
                    //font-weight: 400;

                    a {
                      color: black;

                      &:hover {
                        text-decoration: underline;
                      }
                    }
                  }

                  thead input {
                    width: 100%;
                  }

                  &.table_wraper_09 {
                    margin: 20px 0px 10px 0px;
                  }
                }
                /* stylelint-enable no-descending-specificity */

                .swiper {
                  width: 100%;
                  overflow: scroll;
                  -webkit-overflow-scrolling: touch;
                }

                .content_table{
                  width: 110%;
                  $table-header: #1976D2;
                  $table-header-border: #1565C0;
                  $table-border: #d9d9d9;
                  $row-bg: #f4f2f1;
                  $row-bg: #c5c5c5;

                  div {
                    box-sizing: border-box;
                  }

                  .table-container {
                    display: block;
                    margin: 2em auto;
                    max-width: 100%;
                    margin-bottom: 50px;

                    .flex-table {
                      display: flex;
                      flex-flow: row wrap;
                      border-left: solid 1px $table-border;
                      transition: 0.5s;

                      .flex-row {
                        width: calc(100% / 5);
                        text-align: center;
                        padding: 0.5em 0.5em;
                        border-right: solid 1px $table-border;
                        border-bottom: solid 1px $table-border;
                      }

                      &.flex-table-2 {
                        .flex-row {
                          width: calc(100% / 2);
                        }
                      }
                      &.flex-table-4 {
                        .flex-row {
                          width: calc(100% / 4);
                        }
                      }
                      &.flex-table-5 {
                        .flex-row {
                          width: calc(100% / 5);
                        }
                      }
                      &.flex-table-8 {
                        .flex-row {
                          width: calc(100% / 8);
                        }
                      }

                      &:first-of-type {
                        border-top: solid 1px $table-header-border;
                        border-left: solid 1px $table-header-border;
                      }
                      &:first-of-type .flex-row {
                        color: white;
                        background: $table-header;
                        border-color: $table-header-border;
                      }
                      &.row:nth-child(odd) .flex-row {
                        background: $row-bg;
                      }
                      &:hover {
                        background: #F5F5F5;
                        transition: 500ms;
                      }
                    }

                    /* stylelint-disable no-descending-specificity */
                    &.table-container-color-1 {

                      .flex-table {

                        &:first-of-type {
                          border-top: solid 1px $color-border-01;
                          border-left: solid 1px $color-border-01;

                          .flex-row {
                            background: $color-01;
                            border-color: $color-border-01;
                          }
                        }
                      }
                    }
                    &.table-container-color-2 {

                      .flex-table {

                        &:first-of-type {
                          border-top: solid 1px $color-border-02;
                          border-left: solid 1px $color-border-02;

                          .flex-row {
                            background: $color-02;
                            border-color: $color-border-02;
                          }
                        }
                      }
                    }
                    &.table-container-color-3 {

                      .flex-table {

                        &:first-of-type {
                          border-top: solid 1px $color-border-03;
                          border-left: solid 1px $color-border-03;

                          .flex-row {
                            background: $color-03;
                            border-color: $color-border-03;
                          }
                        }
                      }
                    }
                    &.table-container-color-4 {

                      .flex-table {

                        &:first-of-type {
                          border-top: solid 1px $color-border-04;
                          border-left: solid 1px $color-border-04;

                          .flex-row {
                            background: $color-04;
                            border-color: $color-border-04;
                          }
                        }
                      }
                    }
                    &.table-container-color-5 {

                      .flex-table {

                        &:first-of-type {
                          border-top: solid 1px $color-border-05;
                          border-left: solid 1px $color-border-05;

                          .flex-row {
                            background: $color-05;
                            border-color: $color-border-05;
                          }
                        }
                      }
                    }
                    &.table-container-color-6 {

                      .flex-table {

                        &:first-of-type {
                          border-top: solid 1px $color-border-06;
                          border-left: solid 1px $color-border-06;

                          .flex-row {
                            background: $color-06;
                            border-color: $color-border-06;
                          }
                        }
                      }
                    }
                    /* stylelint-enable no-descending-specificity */
                  }
                }

                .content_table_half {
                  width: 100%;

                  &.content_table_half_01 {
                    padding-right: 0px;

                    @include media-breakpoint-up(sm) {
                      padding-right: 20px;
                    }
                  }
                  &.content_table_half_02 {
                    padding-left: 0px;

                    @include media-breakpoint-up(sm) {
                      padding-left: 20px;
                    }
                  }

                  p {
                    margin-top: 10px;
                    font-weight: 500;
                  }

                  @include media-breakpoint-up(md) {
                    display: inline-block;
                    margin-right: -4px;
                    width: 50%;
                  }
                }

                .content_image  {
                  width: 100%;
                  max-width: 800px;
                  margin-bottom: 50px;
                  margin-top: 25px;

                  &.content_image_01 {
                    max-width: 624px;
                  }
                  &.content_image_02 {
                    max-width: 580px;
                  }
                  &.content_image_03_01 {
                    max-width: 667px;
                  }
                  &.content_image_03_02 {
                    max-width: 600px;
                  }



                  &.content_image_05 {
                    max-width: 594px;
                  }

                  img {
                    width: 100%;
                  }

                  padding: 0 10px;

                  @include media-breakpoint-up(sm) {
                    padding: 0 10px;
                  }
                  @include media-breakpoint-up(md) {
                    padding: 0 15px;
                  }
                  @include media-breakpoint-up(lg) {
                    padding: 0;
                  }
                }

                .content-image-sequence {
                  width: 100%;
                  margin-bottom: 50px;
                  margin-top: 20px;
                  display: flex;
                  flex-wrap: wrap;
                  gap: 15px;

                  .sequence_image_wrapper {
                    width: calc(50% - 8px);

                    .image {
                      width: 100%;
                      margin-bottom: 20px;

                      img {
                        width: 100%;
                      }
                    }
                    .text {
                      position: relative;
                      padding-left: 25px;

                      .number {
                        position: absolute;
                        top:-4px;
                        left:0;
                        color: black;
                      }
                    }
                  }
                  @include media-breakpoint-up(sm) {
                    gap: 15px;

                    .sequence_image_wrapper {
                      width: calc(50% - 8px);
                    }
                  }
                  @include media-breakpoint-up(md) {
                    gap: 15px;

                    .sequence_image_wrapper {
                      width: calc(33% - 10px);
                    }
                  }
                  @include media-breakpoint-up(lg) {
                    gap: 20px;

                    .sequence_image_wrapper {
                      width: calc(25% - 15px);
                    }
                  }
                  @include media-breakpoint-up(xl) {
                    gap: 20px;

                    .sequence_image_wrapper {
                      width: calc(20% - 20px);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.button_update {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 10px;
  color: $color-01;
  z-index: 99999;
  align-items: center;
  justify-content: center;

  &.show {
    display: flex;
  }
}

#footer {

  .pb-40 {
    padding-bottom: 40px;
  }

  .footer_content {
    padding: 0 10px;

    .footer_links{
      padding-bottom: 10px;
    }

    p {
      padding: 0 !important;
    }

    a {
      padding-bottom: 10px;
    }

    @include media-breakpoint-up(sm) {

      padding: 0 10px;
    }
    @include media-breakpoint-up(md) {
      padding: 0 15px;
    }
    @include media-breakpoint-up(lg) {
      padding: 0;

    }
  }

  .footer_icon {
    display: flex;
    padding: 0 10px;

    @include media-breakpoint-up(sm) {
      padding: 0 10px;
    }
    @include media-breakpoint-up(md) {
      padding: 0 15px;
    }
    @include media-breakpoint-up(lg) {
      padding: 0;
    }

    .icon {
      width: 50px;

      img, svg {
        width: 100%;
      }
    }
    .text {
      padding-left: 5px;

      p {
        padding: 0 !important;
      }
    }
  }
}

.version_text {
  width: 100%;
  text-align: center;
  color: #ddd;
}

.dataTables_filter {
  display: none;
  //border: 1px solid #666;
  text-align: right !important;

  .input {
    border: 1px solid #666;
  }
}

select {
  width: 100%;
  background: white;
  border: 1px solid #999;
  color: #999;
  text-align: center;
}

table.dataTable thead > tr > th.sorting_asc::before,
table.dataTable thead > tr > th.sorting_desc::after,
table.dataTable thead > tr > td.sorting_asc::before,
table.dataTable thead > tr > td.sorting_desc::after {
  color: #d42821;
  opacity: 1;
}
